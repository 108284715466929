import { Key } from "react";
import { Action, Dispatch } from "redux";
import { AxiosError, AxiosResponse } from "axios";
import { UploadProps, notification } from "antd";

import {
  ApiService,
  AuthorizedApiService,
  AuthorizedFormApiService,
  BlobApiService,
} from "../../../services/apiServices";
import {
  type MessagesType,
  type PackagePricingType,
  type RegisterFieldType,
} from "../../../models";
import {
  GET_PACKAGE_PRICING,
  GET_EVENT_IMAGE,
  GET_MESSAGES,
  IS_LOADING,
} from "./types";

const setLoading = (loading: boolean) => ({
  type: IS_LOADING,
  payload: loading,
});

export const getPackagePricing = () => async (dispatch: Dispatch<Action>) => {
  ApiService.get("package-pricing")
    .then((res: AxiosResponse) => {
      const { status, data } = res;
      if (status !== 200) {
        notification.error({
          message: "Something went wrong",
        });
        return;
      }

      dispatch({
        type: GET_PACKAGE_PRICING,
        payload: data,
      });
    })
    .catch((err: AxiosError) => {
      notification.error({
        message: "Something went wrong",
      });
    });
};

export const createPackagePricing =
  (packagePricing: PackagePricingType) => async (dispatch: Dispatch<any>) => {
    try {
      AuthorizedApiService.post("/package-pricing", packagePricing)
        .then((res: AxiosResponse) => {
          const { status } = res;

          if (status !== 201) {
            notification.error({
              message: "Something went wrong!",
            });
            return;
          }
          notification.success({
            message: `Package ${packagePricing.package} created!`,
          });
          dispatch(getPackagePricing());
        })
        .catch((err: AxiosError) => {
          notification.error({
            message: "Something went wrong!",
          });
        });
    } catch (err) {
      notification.error({
        message: "Something went wrong!",
      });
    }
  };

export const updatePackagePricing =
  (packagePricing: PackagePricingType, id: Key) =>
  async (dispatch: Dispatch<any>) => {
    try {
      AuthorizedApiService.put(`/package-pricing/${id}`, packagePricing)
        .then((res: AxiosResponse) => {
          const { status } = res;

          if (status !== 200) {
            notification.error({
              message: "Something went wrong!",
            });
            return;
          }
          notification.success({
            message: `Package ${packagePricing.package} updated!`,
          });
          dispatch(getPackagePricing());
        })
        .catch((err: AxiosError) => {
          notification.error({
            message: "Something went wrong!",
          });
        });
    } catch (err) {
      notification.error({
        message: "Something went wrong!",
      });
    }
  };

export const setMessages =
  (messages: MessagesType) => async (dispatch: Dispatch<any>) => {
    try {
      AuthorizedApiService.post("/messages", messages)
        .then((res: AxiosResponse) => {
          const { status } = res;

          if (status !== 201) {
            notification.error({
              message: "Something went wrong!",
            });
            return;
          }
          notification.success({
            message: "Messages created!",
          });
          dispatch(getMessages());
        })
        .catch((err: AxiosError) => {
          notification.error({
            message: "Something went wrong!",
          });
        });
    } catch (err) {
      console.log(err);
    }
  };

export const getMessages =
  (entityId: null | string = null) =>
  async (dispatch: Dispatch<Action>) => {
    try {
      const url = entityId ? `/messages/${entityId}` : `/messages`;

      AuthorizedApiService.get(url)
        .then((res: AxiosResponse) => {
          const { status, data } = res;

          if (status !== 200) {
            notification.error({
              message: "Something went wrong",
            });
            return;
          }

          dispatch({
            type: GET_MESSAGES,
            payload: data[0] ? data[0] : data,
          });
        })
        .catch((err: AxiosError) => {
          notification.error({
            message: "Something went wrong",
          });
        });
    } catch (err) {
      console.log(err);
    }
  };

export const updateMessages =
  (messages: MessagesType, id: string | undefined) =>
  async (dispatch: Dispatch<any>) => {
    try {
      AuthorizedApiService.put(`/messages/${id}`, messages)
        .then((res: AxiosResponse) => {
          const { status, data } = res;

          if (status !== 200) {
            notification.error({
              message: "Something went wrong!",
            });
            return;
          }
          notification.success({
            message: "Messages updated!",
          });
          dispatch(getMessages());
        })
        .catch((err: AxiosError) => {
          notification.error({
            message: "Something went wrong!",
          });
        });
    } catch (err) {
      console.log(err);
    }
  };

export const addEventImage =
  (info: any, entityId: any) => async (dispatch: Dispatch<any>) => {
    try {
      dispatch(setLoading(true));
      const imgUrl = new FormData();
      imgUrl.append("entityId", entityId);
      imgUrl.append("imgUrl", info.file);

      AuthorizedFormApiService.post("/event", imgUrl)
        .then((res: AxiosResponse) => {
          const { status } = res;

          if (status !== 201) {
            notification.error({
              message: "Something went wrong!",
            });
            dispatch(setLoading(false));
            return;
          }
          dispatch(setLoading(false));
          notification.success({
            message: "Image added!",
          });
          dispatch(getEventImageById(entityId));
        })
        .catch((err: AxiosError) => {
          dispatch(setLoading(false));
          notification.error({
            message: "Something went wrong!",
          });
        });
    } catch (err) {
      dispatch(setLoading(false));
      console.log(err);
    }
  };

export const getEventImageById =
  (entityId: string) => async (dispatch: Dispatch<Action>) => {
    try {
      AuthorizedApiService.get(`event/${entityId}`)
        .then((res: any) => {
          const { status, data } = res;

          if (status !== 200) {
            if (res.response.data.message === "Event not found") {
              return;
            }

            notification.error({
              message: "Something went wrong",
            });
            return;
          }

          dispatch({
            type: GET_EVENT_IMAGE,
            payload: data,
          });
        })
        .catch((err: AxiosError) => {
          notification.error({
            message: "Something went wrong",
          });
        });
    } catch (err) {
      console.log(err);
    }
  };

export const exportFormat =
  (entityId: string, format: string) => async (dispatch: Dispatch<Action>) => {
    try {
      BlobApiService.get(`participant/${entityId}/export/${format}`)
        .then((res: AxiosResponse) => {
          const { status, data } = res;

          if (status !== 200) {
            notification.error({
              message: "Something went wrong",
            });
            return;
          }

          const url = window.URL.createObjectURL(new Blob([data]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", `present_${entityId}.${format}`);
          document.body.appendChild(link);
          link.click();
          notification.success({
            message: `Downloaded`,
          });
        })
        .catch((err: AxiosError) => {
          notification.error({
            message: "Something went wrong",
          });
        });
    } catch (err) {
      console.log(err);
    }
  };

export const exportInvoice = () => async (dispatch: Dispatch<Action>) => {
  try {
    BlobApiService.get("generate-invoices")
      .then((res: AxiosResponse) => {
        const { status, data } = res;
        if (status !== 200) {
          notification.error({
            message: "Something went wrong",
          });
          return;
        }

        const url = window.URL.createObjectURL(new Blob([data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "invoices.zip");
        document.body.appendChild(link);
        link.click();
        notification.success({
          message: `Downloaded`,
        });
      })
      .catch((err: AxiosError) => {
        notification.error({
          message: "Something went wrong",
        });
      });
  } catch (err) {
    notification.error({
      message: "Something went wrong",
    });
  }
};

export const generateAcreditations = () => async (dispatch: Dispatch<Action>) => {
  try {
    BlobApiService.get("generate-accreditations")
      .then((res: AxiosResponse) => {
        const { status, data } = res;
        if (status !== 200) {
          notification.error({
            message: "Something went wrong",
          });
          return;
        }

        const url = window.URL.createObjectURL(new Blob([data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "accreditations.zip");
        document.body.appendChild(link);
        link.click();
        notification.success({
          message: `Downloaded`,
        });
      })
      .catch((err: AxiosError) => {
        notification.error({
          message: "Something went wrong",
        });
      });
  } catch (err) {
    notification.error({
      message: "Something went wrong",
    });
  }
};