import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import {
  Button,
  Checkbox,
  Form,
  Input,
  Radio,
  Select,
  Space,
  Spin,
} from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import { Action, Dispatch } from "redux";

import { countryOptions } from "../../../utils/countryOptions";
import mastercard from "../../../images/mastercard.svg";
import maestro from "../../../images/maestro.svg";
import visa from "../../../images/visa.svg";
import idCheck from "../../../images/id-check.svg";
import visaSecure from "../../../images/visa-secure.svg";
import uniCreditBank from "../../../images/UniCreditBank.png";

import {
  CompaniesResponse,
  PublicRegisterFormValuesType,
} from "../../../models";

const StepThree = ({
  wayOfParticipation,
  onFinish,
  isLoading,
  searchedCompanies,
  getSearchedCompanies,
  selectedCompany,
  setSelectedCompany,
}: {
  wayOfParticipation: string | null;
  onFinish: (values: PublicRegisterFormValuesType) => void;
  isLoading: boolean;
  searchedCompanies: CompaniesResponse[];
  getSearchedCompanies: (
    search?: any
  ) => (dispatch: Dispatch<Action>) => Promise<void>;
  selectedCompany: any;
  setSelectedCompany: React.Dispatch<any>;
}) => {
  const [form] = Form.useForm();
  const [isPravnoLice, setIsPravnoLice] = useState(
    wayOfParticipation === "PRAVNO LICE"
  );

  useEffect(() => {
    form.setFieldsValue({
      companyName: selectedCompany.name,
      address: selectedCompany?.address,
      postalCodeAndCity: selectedCompany?.postalCodeAndCity,
      country: selectedCompany?.country,
      companyEmail: selectedCompany?.email,
      phoneNumber: selectedCompany?.phoneNumber,
      ID: selectedCompany?.idNumber,
      PDV: selectedCompany?.vatNumber,
    });
  }, [selectedCompany]);

  const onSearch = (value: string) => {
    const MIN_VALUE_LENGTH = 3;
    if (value.length < MIN_VALUE_LENGTH) {
      return;
    }
    form.setFieldsValue({ companyName: value });
    getSearchedCompanies(value);
  };

  const onSelectCompany = (value: string) => {
    if (!value) {
      return;
    }
    setSelectedCompany(
      searchedCompanies.find(
        (company: CompaniesResponse) => company.id === value
      )
    );
  };

  const validateUniqueEmails = (_: any, value: string) => {
    const emails = form.getFieldValue(['participants']) || [];
    
    // Collect all entered emails and filter out empty ones
    const emailList = emails
      .map((participant: { email: string }) => participant?.email)
      .filter(Boolean);
  
    // Count occurrences of each email
    const emailCount = emailList.reduce((acc: any, email: string) => {
      acc[email] = (acc[email] || 0) + 1;
      return acc;
    }, {});
  
    // Check if the current email is duplicated
    if (emailList.length > 0 && emailCount[value] > 1) {
      return Promise.reject(new Error('Email addresses must be unique.'));
    }
  
    return Promise.resolve();
  };
  

  return (
    <div className="mt-4  sm:mb-10">
      <div className="text-center text-custom-gray-700 font-lato text-[14px] sm:text-[19px] 2xl:text-[24px] mb-4">
        Ispunite registracijsku formu!
      </div>
      <Form
        layout="vertical"
        form={form}
        onFinish={onFinish}
        colon={false}
        initialValues={{ participants: [""] }}
      >
        <div className="w-[90%] xl:w-3/5 m-auto">
          <div className="hidden">
            <Form.Item name="honeypot">
              <Input type="text" />
            </Form.Item>
          </div>
          {isPravnoLice && (
            <>
              <div className=" text-[14px] 2xl:text-[16px] text-custom-pink-900 font-extrabold font-saira mb-2">
                A) PODACI O KOMPANIJI / ORGANIZACIJI
              </div>
              <div className="md:grid grid-cols-2 gap-5">
                <div>
                  <Form.Item
                    label="Naziv kompanije / organizacije"
                    name="companyName"
                    rules={[
                      {
                        required: true,
                        message: "Unesite naziv vaše kompanije/organizacije",
                      },
                    ]}
                  >
                    <Select
                      placeholder="Unesite naziv vaše kompanije ili organizacije"
                      value={!selectedCompany ? null : selectedCompany}
                      showSearch
                      defaultActiveFirstOption={false}
                      filterOption={false}
                      onSearch={onSearch}
                      notFoundContent={null}
                      options={searchedCompanies?.map((company: any) => ({
                        value: company.id,
                        label: company.name,
                      }))}
                      onSelect={onSelectCompany}
                      allowClear={selectedCompany ? true : false}
                      onClear={() => setSelectedCompany("")}
                    />
                  </Form.Item>
                  <Form.Item
                    label="Adresa"
                    name="address"
                    rules={[
                      {
                        required: true,
                        message: "Unesite adresu vaše kompanije/organizacije",
                      },
                    ]}
                  >
                    <Input
                      placeholder="Unesite adresu vaše kompanije ili organizacije"
                      disabled={selectedCompany}
                    />
                  </Form.Item>
                  <Form.Item
                    label="Poštanski broj i grad"
                    name="postalCodeAndCity"
                    rules={[
                      {
                        required: true,
                        message: "Unesite poštanski broj i grad",
                      },
                    ]}
                  >
                    <Input
                      placeholder="Unesite poštanski broj i grad"
                      disabled={selectedCompany}
                    />
                  </Form.Item>
                  <Form.Item
                    label="Država"
                    name="country"
                    rules={[
                      {
                        required: true,
                        message:
                          "Odaberite državu u kojoj je registrovana kompanija",
                      },
                    ]}
                  >
                    <Select
                      placeholder="Odaberite državu u kojoj je registrovana vaša kompanija"
                      options={countryOptions.map((item) => ({
                        label: item.label,
                        value: item.value,
                      }))}
                      disabled={selectedCompany}
                    />
                  </Form.Item>
                </div>
                <div>
                  <Form.Item
                    label="e-mail adresa"
                    name="companyEmail"
                    rules={[
                      {
                        required: true,
                        message:
                          "Unesite email adresu  vaše kompanije/organizacije",
                      },
                      { type: "email" },
                    ]}
                  >
                    <Input
                      type="email"
                      placeholder="Unesite email adresu  vaše kompanije ili organizacije"
                      disabled={selectedCompany}
                    />
                  </Form.Item>
                  <Form.Item
                    label="Kontakt telefon"
                    name="phoneNumber"
                    rules={[
                      {
                        required: true,
                        message:
                          "Unesite kontakt telefon  vaše kompanije/organizacije",
                      },
                    ]}
                  >
                    <Input
                      placeholder="Unesite kontakt telefon  vaše kompanije ili organizacije"
                      type="number"
                      min={0}
                      disabled={selectedCompany}
                    />
                  </Form.Item>
                  <Form.Item
                    label="ID broj"
                    name="ID"
                    rules={[
                      {
                        required: true,
                        message: "Unesite ID broj vaše kompanije/organizacije",
                      },
                    ]}
                  >
                    <Input
                      placeholder="Unesite ID broj vaše kompanije ili organizacije"
                      type="number"
                      min={0}
                      disabled={selectedCompany}
                    />
                  </Form.Item>
                  <Form.Item
                    label="PDV broj"
                    name="PDV"
                    rules={[
                      {
                        message: "Unesite PDV broj vaše kompanije/organizacije",
                      },
                    ]}
                  >
                    <Input
                      placeholder="Unesite PDV broj vaše kompanije ili organizacije"
                      type="number"
                      min={0}
                      disabled={selectedCompany}
                    />
                  </Form.Item>
                </div>
              </div>
            </>
          )}
          <div className=" text-[14px] 2xl:text-[16px] text-custom-pink-900 font-extrabold font-saira my-4">
            {isPravnoLice ? "B" : "A"}) PODACI O UČESNICIMA
          </div>
          <div>
            <Form.List name="participants">
              {(fields, { add, remove }) => (
                <>
                  {fields.map((field, index) => (
                    <div key={field.key}>
                      <div className="flex items-center gap-10 mt-2 mb-1">
                        <div className=" text-[13px] 2xl:text-[14px] text-custom-pink-900 font-extrabold font-saira ">
                          UČESNIK {index + 1}
                        </div>

                        {index > 0 ? (
                          <button
                            onClick={() => {
                              remove(field.name);
                            }}
                            className="text-[red] border border-[red] px-3 rounded-md text-[13px] 2xl:text-[14px] font-semibold"
                          >
                            OBRIŠI
                          </button>
                        ) : null}
                      </div>

                      <div className="md:grid grid-cols-2 gap-5">
                        <div>
                          <Form.Item
                            label="Ime učesnika"
                            name={[field.name, "firstName"]}
                            rules={[
                              {
                                required: true,
                                message: "Unesite ime učesnika",
                              },
                            ]}
                          >
                            <Input placeholder="Unesite ime učesnika" />
                          </Form.Item>

                          <Form.Item
                            label="Prezime učesnika"
                            name={[field.name, "lastName"]}
                            rules={[
                              {
                                required: true,
                                message: "Unesite prezime učesnika",
                              },
                            ]}
                          >
                            <Input placeholder="Unesite prezime učesnika" />
                          </Form.Item>

                          {!isPravnoLice && (
                            <>
                              <Form.Item
                                label="Adresa učesnika"
                                name={[field.name, "address"]}
                                rules={[
                                  {
                                    required: true,
                                    message: "Unesite adresu učesnika",
                                  },
                                ]}
                              >
                                <Input placeholder="Unesite adresu učesnika" />
                              </Form.Item>

                              <Form.Item
                                label="Poštanski broj i grad"
                                name={[field.name, "postalCodeAndCity"]}
                                rules={[
                                  {
                                    required: true,
                                    message: "Unesite poštanski broj i grad",
                                  },
                                ]}
                              >
                                <Input placeholder="Unesite poštanski broj i grad" />
                              </Form.Item>
                            </>
                          )}
                        </div>
                        <div>
                          {!isPravnoLice && (
                            <>
                              <Form.Item
                                label="Država"
                                name={[field.name, "country"]}
                                rules={[
                                  {
                                    required: true,
                                    message:
                                      "Odaberite državu iz koje dolazite",
                                  },
                                ]}
                              >
                                <Select
                                  placeholder="Odaberite državu  iz koje dolazite"
                                  options={countryOptions.map((item) => ({
                                    label: item.label,
                                    value: item.value,
                                  }))}
                                />
                              </Form.Item>
                            </>
                          )}

                          <Form.Item
                            label="e-mail adresa učesnika"
                            name={[field.name, "email"]}
                            rules={[
                              {
                                required: true,
                                message: "Unesite email adresu učesnika",
                              },
                              { type: "email" },
                              { validator: validateUniqueEmails }
                            ]}
                          >
                            <Input
                              type="email"
                              placeholder="Unesite email adresu učesnika"
                            />
                          </Form.Item>
                          <Form.Item
                            label="Kontakt telefon učesnika"
                            name={[field.name, "phoneNumber"]}
                          >
                            <Input
                              placeholder="Unesite kontakt telefon učesnika"
                              type="number"
                              min={0}
                            />
                          </Form.Item>

                          {!isPravnoLice && (
                            <>
                              <Form.Item
                                label="Kompanija / organizacija učesnika ( opcionalno )"
                                name={[field.name, "companyName"]}
                              >
                                <Input placeholder="Unesite naziv kompanije / organizacije u kojoj ste zaposleni" />
                              </Form.Item>
                            </>
                          )}
                        </div>
                      </div>
                    </div>
                  ))}

                  <button
                    className="flex items-center gap-3 mt-2"
                    type="button"
                    onClick={() => add()}
                  >
                    <div className="bg-custom-pink-900 flex justify-center items-center size-8 rounded-full  shadow-[0px_0px_8px_rgba(0,0,0,0.12),0px_8px_8px_rgba(0,0,0,0.24)] text-white">
                      +
                    </div>
                    <div className="font-bold text-[16px] text-custom-gray-800">
                      DODAJ DODATNOG UČESNIKA
                    </div>
                  </button>
                </>
              )}
            </Form.List>
          </div>
          <div className=" text-[14px] 2xl:text-[16px] text-custom-pink-900 font-extrabold font-saira mt-10 mb-4">
            {isPravnoLice ? "C" : "B"}) NAČIN PLAĆANJA
          </div>
          <Form.Item
            name="payment"
            rules={[
              {
                required: true,
                message: "Odaberite način plaćanja",
              },
            ]}
          >
            <Radio.Group>
              <Space direction="vertical">
                <Radio className="mb-2" value="predračun">
                  PREDRAČUN (Ispostavljanje predračuna u .pdf formatu na e-mail
                  adresu kompanije i prvog unesenog učesnika)
                </Radio>
                <Radio value="online">
                  <div className="sm:flex items-center gap-6">
                    <div>ONLINE</div>
                    <div className="flex sm:gap-4">
                      <Link
                        to={"https://www.mastercard.ba/bs-ba.html"}
                        target="_blank"
                      >
                        <img
                          src={mastercard}
                          alt="mastercard"
                          className="mt-1"
                        />
                      </Link>
                      <Link
                        to={"https://www.mastercard.com/brandcenter/en/home"}
                        target="_blank"
                      >
                        <img src={maestro} alt="maestro" className="mt-1" />
                      </Link>
                      <Link
                        to={
                          "https://www.visa.co.uk/about-visa/visa-in-europe.html"
                        }
                        target="_blank"
                        className="mt-1"
                      >
                        <img src={visa} alt="visa" />
                      </Link>
                      <img src={idCheck} alt="idCheck" />
                      <img src={visaSecure} alt="visaSecure" />
                    </div>
                    <Link to={"https://monri.com/"} target="_blank">
                      <img
                        className="h-8 sm:h-10"
                        src={uniCreditBank}
                        alt="pay@web"
                      />
                    </Link>
                  </div>
                </Radio>
              </Space>
            </Radio.Group>
          </Form.Item>
          <div className=" text-[14px] 2xl:text-[16px] text-custom-pink-900 font-extrabold font-saira mt-10 mb-4">
            {isPravnoLice ? "D" : "C"}) ZAŠTITA PODATAKA
          </div>
          <Form.Item
            name="agreement"
            valuePropName="checked"
            rules={[
              {
                validator: async (_, checked) => {
                  if (!checked) {
                    return Promise.reject(
                      new Error("Potvrdite saglasnost sa politikom privatnosti")
                    );
                  }
                },
              },
            ]}
          >
            <Checkbox>
              Ovim putem potvrđujem da sam saglasan sa{" "}
              <span className="text-custom-pink-900">
                <Link
                  to="https://yes-css.ba/politika-privatnosti/"
                  target="_blank"
                >
                  politikom privatnosti*
                </Link>
              </span>
            </Checkbox>
          </Form.Item>
          <Form.Item className="flex sm:justify-center">
            <Button
              disabled={isLoading}
              htmlType="submit"
              className="text-white bg-custom-pink-900 border border-custom-pink-900  sm:w-[210px] h-10 sm:text-[16px] font-bold  rounded-[2px] shadow mt-6"
            >
              REGISTRACIJA
            </Button>
          </Form.Item>
          <div className="flex sm:justify-center h-10 sm:h-4">
            {isLoading && (
              <Spin
                indicator={
                  <LoadingOutlined
                    style={{ fontSize: 36, color: "black" }}
                    spin
                  />
                }
              />
            )}
          </div>
        </div>
      </Form>
    </div>
  );
};

export default StepThree;
