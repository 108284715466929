import React, { useEffect } from "react";
import { ConnectedProps } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";

import connector from "./Container";
import { Button, Typography } from "antd";
import { NavigationPath } from "../../utils";

const Welcome = ({ state, actions }: ConnectedProps<typeof connector>) => {
  const { participant } = state;
  const { id } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    id && actions.getParticipantById(id);
  }, []);

  /*  useEffect(() => {
    if (participant?.previousStatus === "Prisutan") {
      navigate(`/participant/${id}`)
    }
  }, )
 */
  useEffect(() => {
    if (participant?.status !== "Prisutan") {
      actions.updateParticipantStatus(id, "Prisutan");
    }
  });

  return participant?.status === "Prisutan" ? (
    <div className=" text-center mt-40 ">
      <Typography className="text-xl text-custom-gray font-bold">
        DOBRO DOŠAO
      </Typography>
      <Typography className="text-2xl text-dark-blue font-bold">
        {participant?.firstName.toUpperCase()}{" "}
        {participant?.lastName.toUpperCase()}
      </Typography>
      <Button
        type="primary"
        onClick={() => navigate(NavigationPath.PARTICIPANTS_ROUTE)}
        className="bg-dark-blue w-[132px] font-medium text-sm rounded-sm mt-20"
      >
        NAZAD
      </Button>
    </div>
  ) : (
    <div></div>
  );
};

export default Welcome;
